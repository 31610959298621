define("discourse/plugins/discourse-ai/discourse/components/ai-split-topic-suggester", ["exports", "@glimmer/component", "@glimmer/tracking", "@ember/helper", "@ember/modifier", "@ember/object", "@ember/service", "discourse/components/d-button", "discourse/helpers/category-badge", "discourse/lib/ajax", "discourse/lib/ajax-error", "float-kit/components/d-menu", "truth-helpers/helpers/eq", "@ember/template-factory", "@ember/component"], function (_exports, _component, _tracking, _helper, _modifier, _object, _service, _dButton, _categoryBadge, _ajax, _ajaxError, _dMenu, _eq, _templateFactory, _component2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _AiSplitTopicSuggester;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  let AiSplitTopicSuggester = _exports.default = (_class = (_AiSplitTopicSuggester = class AiSplitTopicSuggester extends _component.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "site", _descriptor, this);
      _initializerDefineProperty(this, "menu", _descriptor2, this);
      _initializerDefineProperty(this, "suggestions", _descriptor3, this);
      _initializerDefineProperty(this, "loading", _descriptor4, this);
      _initializerDefineProperty(this, "icon", _descriptor5, this);
      _defineProperty(this, "SUGGESTION_TYPES", {
        title: "suggest_title",
        category: "suggest_category",
        tag: "suggest_tags"
      });
    }
    get input() {
      return this.args.selectedPosts.map(item1 => item1.cooked).join("\n");
    }
    get disabled() {
      return this.loading || this.suggestions.length > 0;
    }
    loadSuggestions() {
      if (this.loading || this.suggestions.length > 0) {
        return;
      }
      this.loading = true;
      (0, _ajax.ajax)(`/discourse-ai/ai-helper/${this.args.mode}`, {
        method: "POST",
        data: {
          text: this.input
        }
      }).then(result1 => {
        if (this.args.mode === this.SUGGESTION_TYPES.title) {
          this.suggestions = result1.suggestions;
        } else if (this.args.mode === this.SUGGESTION_TYPES.category) {
          const suggestions1 = result1.assistant.map(s1 => s1.name);
          const suggestedCategories1 = this.site.categories.filter(item1 => suggestions1.includes(item1.name.toLowerCase()));
          this.suggestions = suggestedCategories1;
        } else {
          this.suggestions = result1.assistant.map(s1 => s1.name);
        }
      }).catch(_ajaxError.popupAjaxError).finally(() => {
        this.loading = false;
      });
    }
    applySuggestion(suggestion1, menu1) {
      if (!this.args.mode) {
        return;
      }
      if (this.args.mode === this.SUGGESTION_TYPES.title) {
        this.args.updateAction(suggestion1);
        return menu1.close();
      }
      if (this.args.mode === this.SUGGESTION_TYPES.category) {
        this.args.updateAction(suggestion1.id);
        return menu1.close();
      }
      if (this.args.mode === this.SUGGESTION_TYPES.tag) {
        if (this.args.currentValue) {
          if (Array.isArray(this.args.currentValue)) {
            const updatedTags1 = [...this.args.currentValue, suggestion1];
            this.args.updateAction([...new Set(updatedTags1)]);
          } else {
            const updatedTags1 = [this.args.currentValue, suggestion1];
            this.args.updateAction([...new Set(updatedTags1)]);
          }
        } else {
          this.args.updateAction(suggestion1);
        }
        return menu1.close();
      }
    }
  }, (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
  /*
    
      {{#if this.loading}}
        {{!
          Dynamically changing @icon of DMenu
          causes it to rerender after load and
          close the menu once data is loaded.
          This workaround mimics an icon change of
          the button by adding an overlapping
          disabled button while loading}}
        <DButton
          class="ai-split-topic-loading-placeholder"
          @disabled={{true}}
          @icon="spinner"
        />
      {{/if}}
      <DMenu
        @icon="discourse-sparkles"
        @interactive={{true}}
        @identifier="ai-split-topic-suggestion-menu"
        class="ai-split-topic-suggestion-button"
        data-suggestion-mode={{@mode}}
        {{on "click" this.loadSuggestions}}
        as |menu|
      >
        <ul class="ai-split-topic-suggestion__results">
          {{#unless this.loading}}
            {{#each this.suggestions as |suggestion index|}}
              {{#if (eq @mode "suggest_category")}}
                <li
                  data-name={{suggestion.name}}
                  data-value={{suggestion.id}}
                  class="ai-split-topic-suggestion__category-result"
                  role="button"
                  {{on "click" (fn this.applySuggestion suggestion menu)}}
                >
                  {{categoryBadge suggestion}}
                </li>
              {{else}}
                <li data-name={{suggestion}} data-value={{index}}>
                  <DButton
                    @translatedLabel={{suggestion}}
                    @action={{fn this.applySuggestion suggestion menu}}
                  />
                </li>
              {{/if}}
            {{/each}}
          {{/unless}}
        </ul>
      </DMenu>
    
  */
  {
    "id": "OK/TJTRS",
    "block": "[[[1,\"\\n\"],[41,[30,0,[\"loading\"]],[[[1,\"      \"],[8,[32,0],[[24,0,\"ai-split-topic-loading-placeholder\"]],[[\"@disabled\",\"@icon\"],[true,\"spinner\"]],null],[1,\"\\n\"]],[]],null],[1,\"    \"],[8,[32,1],[[24,0,\"ai-split-topic-suggestion-button\"],[16,\"data-suggestion-mode\",[30,1]],[4,[32,2],[\"click\",[30,0,[\"loadSuggestions\"]]],null]],[[\"@icon\",\"@interactive\",\"@identifier\"],[\"discourse-sparkles\",true,\"ai-split-topic-suggestion-menu\"]],[[\"default\"],[[[[1,\"\\n      \"],[10,\"ul\"],[14,0,\"ai-split-topic-suggestion__results\"],[12],[1,\"\\n\"],[41,[51,[30,0,[\"loading\"]]],[[[42,[28,[31,3],[[28,[31,3],[[30,0,[\"suggestions\"]]],null]],null],null,[[[41,[28,[32,3],[[30,1],\"suggest_category\"],null],[[[1,\"              \"],[11,\"li\"],[16,\"data-name\",[30,3,[\"name\"]]],[16,\"data-value\",[30,3,[\"id\"]]],[24,0,\"ai-split-topic-suggestion__category-result\"],[24,\"role\",\"button\"],[4,[32,2],[\"click\",[28,[32,4],[[30,0,[\"applySuggestion\"]],[30,3],[30,2]],null]],null],[12],[1,\"\\n                \"],[1,[28,[32,5],[[30,3]],null]],[1,\"\\n              \"],[13],[1,\"\\n\"]],[]],[[[1,\"              \"],[10,\"li\"],[15,\"data-name\",[30,3]],[15,\"data-value\",[30,4]],[12],[1,\"\\n                \"],[8,[32,0],null,[[\"@translatedLabel\",\"@action\"],[[30,3],[28,[32,4],[[30,0,[\"applySuggestion\"]],[30,3],[30,2]],null]]],null],[1,\"\\n              \"],[13],[1,\"\\n\"]],[]]]],[3,4]],null]],[]],null],[1,\"      \"],[13],[1,\"\\n    \"]],[2]]]]],[1,\"\\n  \"]],[\"@mode\",\"menu\",\"suggestion\",\"index\"],false,[\"if\",\"unless\",\"each\",\"-track-array\"]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-ai/discourse/components/ai-split-topic-suggester.js",
    "scope": () => [_dButton.default, _dMenu.default, _modifier.on, _eq.default, _helper.fn, _categoryBadge.default],
    "isStrictMode": true
  }), _AiSplitTopicSuggester), _AiSplitTopicSuggester), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "site", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "menu", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "suggestions", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "loading", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "icon", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return "discourse-sparkles";
    }
  }), _applyDecoratedDescriptor(_class.prototype, "loadSuggestions", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "loadSuggestions"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "applySuggestion", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "applySuggestion"), _class.prototype)), _class);
});