define("discourse/plugins/discourse-ai/discourse/components/ai-bot-header-icon", ["exports", "@glimmer/component", "@ember/object", "@ember/service", "truth-helpers", "discourse/components/d-button", "discourse-common/helpers/i18n", "float-kit/components/d-menu", "discourse/plugins/discourse-ai/discourse/lib/ai-bot-helper", "discourse/plugins/discourse-ai/discourse/components/ai-bot-header-panel", "@ember/template-factory", "@ember/component"], function (_exports, _component, _object, _service, _truthHelpers, _dButton, _i18n, _dMenu, _aiBotHelper, _aiBotHeaderPanel, _templateFactory, _component2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _descriptor2, _AiBotHeaderIcon;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  let AiBotHeaderIcon = _exports.default = (_class = (_AiBotHeaderIcon = class AiBotHeaderIcon extends _component.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "siteSettings", _descriptor, this);
      _initializerDefineProperty(this, "composer", _descriptor2, this);
    }
    get bots() {
      return this.siteSettings.ai_bot_add_to_header ? this.siteSettings.ai_bot_enabled_chat_bots.split("|").filter(Boolean) : [];
    }
    compose() {
      (0, _aiBotHelper.composeAiBotMessage)(this.bots[0], this.composer);
    }
  }, (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
  /*
    
      {{#if (gt this.bots.length 0)}}
        <li>
          {{#if (gt this.bots.length 1)}}
            <DMenu
              @icon="robot"
              @title={{i18n "discourse_ai.ai_bot.shortcut_title"}}
              class="ai-bot-button icon btn-flat"
            >
              <:content as |args|>
                <AiBotHeaderPanel @closePanel={{args.close}} />
              </:content>
            </DMenu>
          {{else}}
            <DButton
              @icon="robot"
              @title={{i18n "discourse_ai.ai_bot.shortcut_title"}}
              class="ai-bot-button icon btn-flat"
              @action={{this.compose}}
            />
          {{/if}}
        </li>
      {{/if}}
    
  */
  {
    "id": "LIQczarb",
    "block": "[[[1,\"\\n\"],[41,[28,[32,0],[[30,0,[\"bots\",\"length\"]],0],null],[[[1,\"      \"],[10,\"li\"],[12],[1,\"\\n\"],[41,[28,[32,0],[[30,0,[\"bots\",\"length\"]],1],null],[[[1,\"          \"],[8,[32,1],[[24,0,\"ai-bot-button icon btn-flat\"]],[[\"@icon\",\"@title\"],[\"robot\",[28,[32,2],[\"discourse_ai.ai_bot.shortcut_title\"],null]]],[[\"content\"],[[[[1,\"\\n              \"],[8,[32,3],null,[[\"@closePanel\"],[[30,1,[\"close\"]]]],null],[1,\"\\n            \"]],[1]]]]],[1,\"\\n\"]],[]],[[[1,\"          \"],[8,[32,4],[[24,0,\"ai-bot-button icon btn-flat\"]],[[\"@icon\",\"@title\",\"@action\"],[\"robot\",[28,[32,2],[\"discourse_ai.ai_bot.shortcut_title\"],null],[30,0,[\"compose\"]]]],null],[1,\"\\n\"]],[]]],[1,\"      \"],[13],[1,\"\\n\"]],[]],null],[1,\"  \"]],[\"args\"],false,[\"if\"]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-ai/discourse/components/ai-bot-header-icon.js",
    "scope": () => [_truthHelpers.gt, _dMenu.default, _i18n.default, _aiBotHeaderPanel.default, _dButton.default],
    "isStrictMode": true
  }), _AiBotHeaderIcon), _AiBotHeaderIcon), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "siteSettings", [_service.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "composer", [_service.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "compose", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "compose"), _class.prototype)), _class);
});