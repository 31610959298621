define("discourse/plugins/discourse-ai/initializers/ai-image-caption", ["exports", "discourse/lib/ajax", "discourse/lib/ajax-error", "discourse/lib/api", "discourse-i18n"], function (_exports, _ajax, _ajaxError, _api, _discourseI18n) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _api.apiInitializer)("1.25.0", api => {
    const buttonAttrs = {
      label: _discourseI18n.default.t("discourse_ai.ai_helper.image_caption.button_label"),
      icon: "discourse-sparkles",
      class: "generate-caption"
    };
    const settings = api.container.lookup("service:site-settings");
    const currentUser = api.getCurrentUser();
    if (!settings.ai_helper_enabled_features.includes("image_caption") || !currentUser?.can_use_assistant) {
      return;
    }
    api.addComposerImageWrapperButton(buttonAttrs.label, buttonAttrs.class, buttonAttrs.icon, event => {
      const imageCaptionPopup = api.container.lookup("service:imageCaptionPopup");
      imageCaptionPopup.popupTrigger = event.target;
      if (imageCaptionPopup.popupTrigger.classList.contains("generate-caption")) {
        const buttonWrapper = event.target.closest(".button-wrapper");
        const imageIndex = parseInt(buttonWrapper.getAttribute("data-image-index"), 10);
        const imageSrc = event.target.closest(".image-wrapper").querySelector("img").getAttribute("src");
        imageCaptionPopup.toggleLoadingState(true);
        const site = api.container.lookup("site:main");
        if (!site.mobileView) {
          imageCaptionPopup.showPopup = !imageCaptionPopup.showPopup;
        }
        imageCaptionPopup._request = (0, _ajax.ajax)(`/discourse-ai/ai-helper/caption_image`, {
          method: "POST",
          data: {
            image_url: imageSrc
          }
        });
        imageCaptionPopup._request.then(_ref => {
          let {
            caption
          } = _ref;
          imageCaptionPopup.imageSrc = imageSrc;
          imageCaptionPopup.imageIndex = imageIndex;
          imageCaptionPopup.newCaption = caption;
          if (site.mobileView) {
            // Auto-saves caption on mobile view
            imageCaptionPopup.updateCaption();
          }
        }).catch(_ajaxError.popupAjaxError).finally(() => {
          imageCaptionPopup.toggleLoadingState(false);
        });
      }
    });
  });
});