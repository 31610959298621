define("discourse/plugins/discourse-ai/discourse/components/modal/share-modal", ["exports", "@glimmer/component", "@glimmer/tracking", "@ember/component", "@ember/modifier", "@ember/object", "@ember/service", "@ember/template", "discourse/components/d-button", "discourse/components/d-modal", "discourse-common/helpers/i18n", "discourse-common/lib/later", "discourse-i18n", "discourse/plugins/discourse-ai/discourse/lib/ai-bot-helper", "discourse/plugins/discourse-ai/discourse/lib/copy-conversation", "@ember/template-factory"], function (_exports, _component, _tracking, _component2, _modifier, _object, _service, _template, _dButton, _dModal, _i18n, _later, _discourseI18n, _aiBotHelper, _copyConversation, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _ShareModal;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  let ShareModal = _exports.default = (_class = (_ShareModal = class ShareModal extends _component.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "modal", _descriptor, this);
      _initializerDefineProperty(this, "siteSettings", _descriptor2, this);
      _initializerDefineProperty(this, "currentUser", _descriptor3, this);
      _initializerDefineProperty(this, "contextValue", _descriptor4, this);
      _initializerDefineProperty(this, "htmlContext", _descriptor5, this);
      _initializerDefineProperty(this, "maxContext", _descriptor6, this);
      _initializerDefineProperty(this, "allPosts", _descriptor7, this);
      _initializerDefineProperty(this, "justCopiedText", _descriptor8, this);
      const postStream1 = this.args.model.topic.get("postStream");
      let postNumbers1 = [];
      // simpler to understand than Array.from
      for (let i1 = 1; i1 <= this.args.model.post_number; i1++) {
        postNumbers1.push(i1);
      }
      this.allPosts = postNumbers1.map(postNumber1 => {
        let postId1 = postStream1.findPostIdForPostNumber(postNumber1);
        if (postId1) {
          return postStream1.findLoadedPost(postId1);
        }
      }).filter(post1 => post1);
      this.maxContext = this.allPosts.length / 2;
      this.contextValue = 1;
      this.updateHtmlContext();
    }
    updateHtmlContext() {
      let context1 = [];
      const start1 = this.allPosts.length - this.contextValue * 2;
      for (let i1 = start1; i1 < this.allPosts.length; i1++) {
        const post1 = this.allPosts[i1];
        context1.push(`<p><b>${post1.username}:</b></p>`);
        context1.push(post1.cooked);
      }
      this.htmlContext = (0, _template.htmlSafe)(context1.join("\n"));
    }
    async copyContext() {
      const from1 = this.allPosts[this.allPosts.length - this.contextValue * 2].post_number;
      const to1 = this.args.model.post_number;
      await (0, _copyConversation.default)(this.args.model.topic, from1, to1);
      this.justCopiedText = _discourseI18n.default.t("discourse_ai.ai_bot.conversation_shared");
      (0, _later.default)(() => {
        this.justCopiedText = "";
      }, 2000);
    }
    shareConversationModal(event1) {
      event1?.preventDefault();
      this.args.closeModal();
      (0, _aiBotHelper.showShareConversationModal)(this.modal, this.args.model.topic_id);
      return false;
    }
  }, (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
  /*
    
      <DModal
        class="ai-share-modal"
        @title={{i18n "discourse_ai.ai_bot.share_modal.title"}}
        @closeModal={{@closeModal}}
      >
        <:body>
          <div class="ai-share-modal__preview">
            {{this.htmlContext}}
          </div>
        </:body>
  
        <:footer>
          <div class="ai-share-modal__slider">
            <Input
              @type="range"
              min="1"
              max={{this.maxContext}}
              @value={{this.contextValue}}
              {{on "change" this.updateHtmlContext}}
            />
            <div class="ai-share-modal__context">
              {{i18n "discourse_ai.ai_bot.share_modal.context"}}
              {{this.contextValue}}
            </div>
          </div>
          <DButton
            class="btn-primary confirm"
            @icon="copy"
            @action={{this.copyContext}}
            @label="discourse_ai.ai_bot.share_modal.copy"
          />
          <span class="ai-share-modal__just-copied">{{this.justCopiedText}}</span>
          {{#if this.currentUser.can_share_ai_bot_conversations}}
            <a href {{on "click" this.shareConversationModal}}>
              <span class="ai-share-modal__share-tip">
                {{i18n "discourse_ai.ai_bot.share_modal.share_tip"}}
              </span>
            </a>
          {{/if}}
        </:footer>
      </DModal>
    
  */
  {
    "id": "Ccsc9yzh",
    "block": "[[[1,\"\\n    \"],[8,[32,0],[[24,0,\"ai-share-modal\"]],[[\"@title\",\"@closeModal\"],[[28,[32,1],[\"discourse_ai.ai_bot.share_modal.title\"],null],[30,1]]],[[\"body\",\"footer\"],[[[[1,\"\\n        \"],[10,0],[14,0,\"ai-share-modal__preview\"],[12],[1,\"\\n          \"],[1,[30,0,[\"htmlContext\"]]],[1,\"\\n        \"],[13],[1,\"\\n      \"]],[]],[[[1,\"\\n        \"],[10,0],[14,0,\"ai-share-modal__slider\"],[12],[1,\"\\n          \"],[8,[32,2],[[24,\"min\",\"1\"],[16,\"max\",[30,0,[\"maxContext\"]]],[4,[32,3],[\"change\",[30,0,[\"updateHtmlContext\"]]],null]],[[\"@type\",\"@value\"],[\"range\",[30,0,[\"contextValue\"]]]],null],[1,\"\\n          \"],[10,0],[14,0,\"ai-share-modal__context\"],[12],[1,\"\\n            \"],[1,[28,[32,1],[\"discourse_ai.ai_bot.share_modal.context\"],null]],[1,\"\\n            \"],[1,[30,0,[\"contextValue\"]]],[1,\"\\n          \"],[13],[1,\"\\n        \"],[13],[1,\"\\n        \"],[8,[32,4],[[24,0,\"btn-primary confirm\"]],[[\"@icon\",\"@action\",\"@label\"],[\"copy\",[30,0,[\"copyContext\"]],\"discourse_ai.ai_bot.share_modal.copy\"]],null],[1,\"\\n        \"],[10,1],[14,0,\"ai-share-modal__just-copied\"],[12],[1,[30,0,[\"justCopiedText\"]]],[13],[1,\"\\n\"],[41,[30,0,[\"currentUser\",\"can_share_ai_bot_conversations\"]],[[[1,\"          \"],[11,3],[24,6,\"\"],[4,[32,3],[\"click\",[30,0,[\"shareConversationModal\"]]],null],[12],[1,\"\\n            \"],[10,1],[14,0,\"ai-share-modal__share-tip\"],[12],[1,\"\\n              \"],[1,[28,[32,1],[\"discourse_ai.ai_bot.share_modal.share_tip\"],null]],[1,\"\\n            \"],[13],[1,\"\\n          \"],[13],[1,\"\\n\"]],[]],null],[1,\"      \"]],[]]]]],[1,\"\\n  \"]],[\"@closeModal\"],false,[\"if\"]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-ai/discourse/components/modal/share-modal.js",
    "scope": () => [_dModal.default, _i18n.default, _component2.Input, _modifier.on, _dButton.default],
    "isStrictMode": true
  }), _ShareModal), _ShareModal), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "modal", [_service.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "siteSettings", [_service.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "currentUser", [_service.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "contextValue", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 1;
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "htmlContext", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return "";
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "maxContext", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 0;
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "allPosts", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "justCopiedText", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return "";
    }
  }), _applyDecoratedDescriptor(_class.prototype, "updateHtmlContext", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "updateHtmlContext"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "copyContext", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "copyContext"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "shareConversationModal", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "shareConversationModal"), _class.prototype)), _class);
});