define("discourse/plugins/discourse-ai/discourse/routes/discourse-ai-shared-conversation-show", ["exports", "discourse/routes/discourse"], function (_exports, _discourse) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _discourse.default.extend({
    beforeModel(transition) {
      window.location = transition.intent.url;
      transition.abort();
    }
  });
});